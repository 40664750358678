/* eslint-disable */
import React, { useState, useEffect } from "react";
import EditIcon from '../../assets/img/edit.png';
import DeleteIcon from '../../assets/img/delete_icon.png';
import CloseIcon from '../../assets/img/closebutton.png';
import CommonPopup from "../Common/CommonPopup";
import { GetProductList, logoutUser } from "../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { GET_PRODUCT_LISTS_API_URL, GET_CATEGORY_LISTS_API_URL, LOGIN_URL } from "../Shared/Constant";
import PreviewFiles from "../Common/PreviewFiles";
import moment from "moment";
import PaginationPage from "../Common/PaginationPage";
import CommonLoader from "../Common/CommonLoader";
import CommonLoaderNew from "../Common/CommonLoaderNew";
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const timeSince = (dateParam)=> {
    if (!dateParam) {
        return null;
    }
    const date = typeof dateParam === 'object' ? dateParam : new Date(dateParam);
    const DAY_IN_MS = 86400000; // 24 * 60 * 60 * 1000
    const today = new Date();
    const yesterday = new Date(today - DAY_IN_MS);
    const seconds = Math.round((today - date) / 1000);
    const minutes = Math.round(seconds / 60);
    const isToday = today.toDateString() === date.toDateString();
    const isYesterday = yesterday.toDateString() === date.toDateString();
    const isThisYear = today.getFullYear() === date.getFullYear();
    
    if (seconds < 5) {
        return 'Today';
    } else if (seconds < 60) {
        return `Today`;
    } else if (seconds < 90) {
        return 'Today';
    } else if (minutes < 60) {
        return `Today`;
    } else if (isToday) {
        return 'Today'; // Today at 10:20
    } else if (isYesterday) {
        return 'Yesterday'; // Yesterday at 10:20
    } else if (isThisYear) {
        return moment.utc(dateParam).local().format('MMM D, YYYY'); // 10. January at 10:20
    }
    return moment.utc(dateParam).local().format('MMM D, YYYY'); // 10. January 2017. at 10:20
};

const BookManagement = () => {
    const { accessToken, productlist, categoryObjects, profiledata } = useSelector((state) => state.allReducers);
    const [ ModalCommon, setModalCommon ] = useState({
        open: false,
        title: "",
        submitbtn: "",
        cancelbtn: "",
        modaltype: "",
        description: ""
    });
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [ productSearch, setProductSearch ] = useState("");
    const [ currentPage, setCurrentPage ] = useState(1);
    const [ categoryId, setCategoryId ] = useState("");
    const [ paginationLength, setPaginationLength ] = useState({});
    const [ productType, setProductType ] = useState("all");
    const [ categoryListShow, setCategoryListShow ] = useState([]);
    const [ categoryDropdown, setCategoryDropdown ] = useState(false);
    const [ userLimit, setUserLimit ] = useState(10);
    const [ commonLoaderState, setCommonLoaderState ] = useState(false);
    const [ ListLoader, setListLoader ] = useState(false);

    useEffect(() => {
        if(process.env.REACT_APP_TITLE === "bundles of books") {
            if(productSearch !== "") {
                setListLoader(true);
                window.axios.get(`${GET_CATEGORY_LISTS_API_URL}/all/5000/1/${productSearch}`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'Authorization': 'Bearer ' + accessToken,
                    }
                }).then(function (result) {
                    setListLoader(false);
                    setCategoryListShow(result.data.data);
                })  
                .catch(function (result) {
                    setListLoader(false);
                    setCategoryListShow([]);
                });
            } else {
                setProductSearch("");
                setCategoryListShow([]);
            }
        }
    }, [ productSearch ]);


    useEffect(() => {
        if(productSearch !== "") {
            setCurrentPage(1);
        }
    }, [ productSearch ]);

    // select product status
    const SelectProductStatus = (e) => {
        setProductType(e.target.value)
        setCurrentPage(1);
    }

    const selectLimitProduct = (value) => {
        setUserLimit(value);
        setCurrentPage(1);
    };

    // select category function
    const SelectCategory = (elm) => {
        setCategoryId(elm?.id);
        setProductSearch(elm?.name);
        setCategoryListShow([]);
        setCategoryDropdown(false);
    };

    // page change
    const currentFunction = (page) => {
        setCurrentPage(page);
    };

    useEffect(() => {
        GetProductListFnct();
    }, [ categoryId, currentPage, productType, userLimit ]);

    // search product function
    const PreductSearchFct = (e) => {
        setProductSearch(e.target.value);
        if(e.target.value !== "") {
            setCategoryDropdown(true);
        } else {
            setCategoryDropdown(false);
        }
    };
    
    const ClearSearchFiled = () => {
        setProductSearch("");
        setCategoryDropdown(false);
        setCategoryId("");
    };

    // get product list
    const GetProductListFnct = () => {
        setListLoader(true);
        window.axios.get(`${GET_PRODUCT_LISTS_API_URL}/${productType}/${userLimit}/${currentPage}/${categoryId === "" ? "none" : categoryId}`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + accessToken,
            }
        }).then(function (result) {
            setListLoader(false);
            dispatch(GetProductList(result.data.data));
            setPaginationLength(result.data.pagination)
        })  
        .catch(function (result) {
            setListLoader(false);
            dispatch(GetProductList([]));
            if(result?.response?.status === 403) {
                dispatch(logoutUser());
                navigate(LOGIN_URL);
            } else if(result?.response?.status === 400) {
                if(result?.response?.data?.error === "Please login with admin user.") {
                    toast.error(result?.response?.data?.error);
                    dispatch(logoutUser());
                    setTimeout(() => navigate(LOGIN_URL), 100);
                } else {
                    toast.error(result?.response?.data?.error);
                };
            };
        });
    };

    // show category list
    const CategoryList = (list) => {
        const CategorySplite = list?.category?.split(",");
        const CategorySpliteGet = CategorySplite?.map((elm, index) => {
            return(<li key={index}>{categoryObjects[elm]?.name}</li>)
        }); 

        return(<ul className="categorytbale">{CategorySpliteGet}</ul>)
    };

    // add books fucntion
    const AddBooks = () => {
        setModalCommon({
            ...ModalCommon,
            open: !ModalCommon?.open,
            title: "Add Book",
            submitbtn: "Add",
            cancelbtn: "Cancel",
            modaltype: "form",
            type: "Add Book"
        });
    };

    // delete book function
    const ProductActiveInfo = (product) => {
        setModalCommon({
            ...ModalCommon,
            open: !ModalCommon?.open,
            title: "Are you sure?",
            description: product?.is_active === true ? 'Do you really want to deactivate product to' : 'Do you really want to active product to',
            submitbtn: product?.is_active === true ? "Yes" : "Yes",
            cancelbtn: "No",
            modaltype: "permision",
            selectedUser: product,
            bookdetails: product,
            type: "is active product"
        });
    };

    // edit book function
    const EditBook = (books) => {
        setModalCommon({
            ...ModalCommon,
            open: !ModalCommon?.open,
            title: "Edit Book",
            submitbtn: "Save",
            cancelbtn: "Cancel",
            modaltype: "form",
            type: "Edit Book",
            bookdetails: books,
        });
    };

    const deleteBook = (item) => {
        setModalCommon({
            ...ModalCommon,
            open: !ModalCommon?.open,
            title: "Are you sure?",
            description: "Do you really want to delete",
            submitbtn: "Yes",
            cancelbtn: "No",
            modaltype: "permision",
            selectedUser: item,
            bookdetails: item,
            type: "is delete book"
        });
    
    }

    return(<div className="col-sm-12 col-lg-12">
    <div className="card-body">
        <div className="card-header-New">
            {process.env.REACT_APP_TITLE === "bundles of books" && (<div className="searchBoxwithbtn">
                <input type="text" className="form-control" value={productSearch} onChange={(e) => PreductSearchFct(e)} placeholder="Search Category ..."/>
                {productSearch === "" ? (<svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-search" width={24} height={24} viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0"></path>
                    <path d="M21 21l-6 -6"></path>
                </svg>) : (<img src={CloseIcon} className="icon searchclear" onClick={() => ClearSearchFiled()} alt="close" />)}
                {categoryDropdown && (<ul className="searchDropdownlist">
                    {categoryListShow && categoryListShow.map((elm, index) => {
                        return(<React.Fragment key={index}>
                            <li onClick={() => SelectCategory(elm)}>{index + 1}.&nbsp;&nbsp; {elm?.name}</li>
                        </React.Fragment>)
                    })}
                </ul>)}
            </div>)}
            <div className="card-header-right">
                <select className="form-control activestatusselet" value={productType} onChange={(e) => SelectProductStatus(e)}>
                    <option value="all">All</option>
                    <option value="active">Active</option>
                    <option value="inactive">Inactive</option>
                </select>
                <select className="form-control userLimitselect" value={userLimit} onChange={(e) => selectLimitProduct(e.target.value)}>
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="30">30</option>
                    <option value="40">40</option>
                    <option value="50">50</option>
                    <option value="60">60</option>
                    <option value="70">70</option>
                    <option value="80">80</option>
                    <option value="90">90</option>
                    <option value="100">100</option>
                </select>
                {(profiledata?.is_admin || (profiledata?.permission?.includes("add-product"))) && (<button className="addbtnset" onClick={() => AddBooks()}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-circle-plus" width={24} height={24} viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0"></path>
                        <path d="M9 12l6 0"></path>
                        <path d="M12 9l0 6"></path>
                    </svg>
                    {process.env.REACT_APP_TITLE === "motivate on pape" ? "Add Poster" : "Add Book"}
                </button>)}
            </div>
        </div>
        <div className="card-table-new">
            <div className="card-table table-responsive">
                <table className="table table-vcenter card-table order-managment-table">
                    <thead>
                        <tr>
                            <th>Books Name</th>
                            <th>Product Image</th>
                            {process.env.REACT_APP_TITLE !== "motivate on pape" && (<th>Category</th>)}
                            {/* <th className="descriptiontable" style={{ width: "240px" }}>Description</th> */}
                            <th>Created At</th>
                            <th>Updated At</th>
                            <th>Price</th>
                            {(profiledata?.is_admin || profiledata?.permission?.includes("update-product")) && (<th>Is Active</th>)}
                            {(profiledata?.is_admin || (profiledata?.permission?.includes("update-product") || profiledata?.permission?.includes("delete-product"))) && (<th>Action</th>)}
                        </tr>
                    </thead>
                    <tbody>
                    {productlist && productlist?.length === 0 ? (<tr>
                            <td colSpan="10" style={{ textAlign: "center" }}>No User Record Found</td>
                        </tr>) : productlist?.map((elm, index) => {
                        return(<tr key={index}>
                            <td>{elm?.name}</td>
                            <td>
                                <PreviewFiles type="productimage" url={elm?.product_img_path} />
                            </td>
                            {process.env.REACT_APP_TITLE !== "motivate on pape" && (<td>
                                <CategoryList category={elm?.category}/>
                            </td>)}
                            {/* <td className="descriptiontable" dangerouslySetInnerHTML={{__html : elm?.description}}></td> */}
                            <td>{timeSince(new Date(elm?.created_at))}</td>
                            <td>{timeSince(new Date(elm?.updated_at))}</td>
                            <td>${elm?.price}</td>
                            {(profiledata?.is_admin || profiledata?.permission?.includes("update-product")) && (<td>
                                <label className="form-check form-switch">
                                    <input className="form-check-input"  type="checkbox" onChange={e => {}} onClick={() => ProductActiveInfo(elm)} checked={elm?.is_active === true ? true : false} />
                                    <span className="form-check-label" >
                                        <div className="post__content" dangerouslySetInnerHTML={{ __html: elm?.is_active === true ? "<span class='badge bg-green-lt'>Yes</span>" : "<span class='badge bg-red-lt'>No</span>"}}></div>
                                    </span>
                                </label>
                            </td>)}
                            {(profiledata?.is_admin || (profiledata?.permission?.includes("update-product") || profiledata?.permission?.includes("delete-product"))) && (<td>
                                <div className="d-flex">
                                    {(profiledata?.is_admin || profiledata?.permission?.includes("update-product")) && (<button className="btn btntable" onClick={() => EditBook(elm)}>
                                        <img src={EditIcon} alt="Edit" />
                                    </button>)}
                                    {(profiledata?.is_admin || profiledata?.permission?.includes("delete-product")) && (<button className="btn btntable" onClick={() => deleteBook(elm)}>
                                        <img src={DeleteIcon} alt="Delete" />
                                    </button>)}
                                </div>
                            </td>)}
                        </tr>)
                    })}
                    </tbody>
                </table>
            </div>
            {productlist?.length > 0 && (<PaginationPage 
                paginationLength={paginationLength}
                totalPages={paginationLength?.total_records}
                currentPage={currentPage}
                onChangePage={currentFunction}
                userLimit={userLimit}
            />)}
        </div>
    </div>

    {/* common modal */}
    {ModalCommon?.open && (<CommonPopup setCommonLoaderState={setCommonLoaderState} ModalCommon={ModalCommon} setModalCommon={setModalCommon}/>)}
    {commonLoaderState && (<CommonLoader />)}

    {ListLoader && (<CommonLoaderNew />)}
</div>)
}

export default BookManagement;