/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { GetOrderList, logoutUser } from "../../redux/actions";
import { GET_ORDER_LIST_API_URL, LOGIN_URL } from "../Shared/Constant";
import moment from "moment";
import PreviewFiles from "../Common/PreviewFiles";
import PaginationPage from "../Common/PaginationPage";
import CloseIcon from '../../assets/img/closebutton.png';
import CommonLoaderNew from "../Common/CommonLoaderNew";
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const timeSince = (dateParam)=> {
    if (!dateParam) {
        return null;
    }
    const date = typeof dateParam === 'object' ? dateParam : new Date(dateParam);
    const DAY_IN_MS = 86400000; // 24 * 60 * 60 * 1000
    const today = new Date();
    const yesterday = new Date(today - DAY_IN_MS);
    const seconds = Math.round((today - date) / 1000);
    const minutes = Math.round(seconds / 60);
    const isToday = today.toDateString() === date.toDateString();
    const isYesterday = yesterday.toDateString() === date.toDateString();
    const isThisYear = today.getFullYear() === date.getFullYear();
    
    if (seconds < 5) {
        return 'Today';
    } else if (seconds < 60) {
        return `Today`;
    } else if (seconds < 90) {
        return 'Today';
    } else if (minutes < 60) {
        return `Today`;
    } else if (isToday) {
        return 'Today'; // Today at 10:20
    } else if (isYesterday) {
        return 'Yesterday'; // Yesterday at 10:20
    } else if (isThisYear) {
        return moment.utc(dateParam).local().format('MMM D, YYYY'); // 10. January at 10:20
    }
    return moment.utc(dateParam).local().format('MMM D, YYYY'); // 10. January 2017. at 10:20
};

const UserManagement = () => {
    const { accessToken, orderlist, productObjects } = useSelector((state) => state.allReducers);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [ orderType, setOrderType ] = useState("all");
    const [ listLimit, setListLimit ] = useState(10);
    const [ currentPage, setCurrentPage ] = useState(1);
    const [ OrderSearch, setOrderSearch ] = useState("");
    const [ paginationLength, setPaginationLength ] = useState({});
    const [ searchNone, setSearchNone ] = useState(false);
    const [ ListLoader, setListLoader ] = useState(false);

    // page change
    const currentFunction = (page) => {
        setCurrentPage(page);
    };

    // clear search box
    const ClearSearchFiled = () => {
        setOrderSearch("");
        setSearchNone(true);
    };

    // category type
    const SelectProductTypeFnct = (e) => {
        setOrderType(e.target.value);
        setCurrentPage(1);
    };

    // product limit function
    const ProductLimit = (e) => {
        setListLimit(e.target.value);
        setCurrentPage(1);
    };

    useEffect(() => {
        GetOrderedListFnct();
    }, [ listLimit, currentPage, orderType, searchNone ]);

    // on Enter search
    const onEnterSearch = (e) => {
        const code = e.which || e.keyCode;
        if(code !== 17 && code !== 18) {
            if(code === 13) {
                GetOrderedListFnct(1);
            } else if(OrderSearch === "" || OrderSearch?.length <= 1) {
                GetOrderedListFnct();
            };
        };
    };

    // get order list
    const GetOrderedListFnct = (pages) => {
        setListLoader(true);
        if(pages !== undefined) {
            setCurrentPage(pages);
        };
        window.axios.get(`${GET_ORDER_LIST_API_URL}/${orderType}/${listLimit}/${pages === undefined ? currentPage : pages}/${OrderSearch === "" ? "none" : OrderSearch}`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + accessToken,
            }
        }).then(function (result) {
            setListLoader(false);
            setSearchNone(false);
            dispatch(GetOrderList(result.data.data));
            setPaginationLength(result.data.pagination);
        })  
        .catch(function (result) {
            setListLoader(false);
            dispatch(GetOrderList([]));
            if(result?.response?.status === 403) {
                dispatch(logoutUser());
                navigate(LOGIN_URL);
            } else if(result?.response?.status === 400) {
                if(result?.response?.data?.error === "Please login with admin user.") {
                    toast.error(result?.response?.data?.error);
                    dispatch(logoutUser());
                    setTimeout(() => navigate(LOGIN_URL), 100);
                } else {
                    toast.error(result?.response?.data?.error);
                };
            };
        });
    };

    // donwload file
    // const DownloadFile = (file) => {
    //     fetch(process.env.REACT_APP_BASE_URL+file?.product_file_path)
    //         .then(response => response.blob())
    //         .then(blob => {
    //         const link = document.createElement("a");
    //         link.href = URL.createObjectURL(blob);
    //         link.download = file.name;
    //         link.click();
    //     })
    //     .catch(console.error);
    // };

    // show product cart list
    const ProductCartList = (cart) => {
        const ProductSplite = cart.split(",");
        const ProductSpliteGet = ProductSplite.map((elm, index) => {
            return(<li key={index}>
                <PreviewFiles type="productimage" url={productObjects[elm]?.product_img_path} />
                <div className="productinfo">
                    <h4>{productObjects[elm]?.name}</h4>
                    {/* <p onClick={() => DownloadFile(productObjects[elm])}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-download icon" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path d="M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-2"></path><polyline points="7 11 12 16 17 11"></polyline><line x1="12" y1="4" x2="12" y2="16"></line></svg>
                        Download
                    </p> */}
                </div>
            </li>)
        }); 

        return(<ul className="productcarttbale">{ProductSpliteGet}</ul>)
    };

    return(<div className="col-sm-12 col-lg-12">
    <div className="card-body">
        <div className="card-header-New">
            <div className="searchBoxwithbtn" style={{ width: '335px' }}>
            {OrderSearch !== "" && (<label id="focusLabel" className="focusLabel">Search after hitting the enter button.</label>)}
                <input type="text" className="form-control" value={OrderSearch} onKeyDown={(e) => onEnterSearch(e)} onChange={(e) => setOrderSearch(e.target.value)} placeholder="Search Order (first name and last name or email)"/>
                {OrderSearch === "" ? (<svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-search" width={24} height={24} viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0"></path>
                    <path d="M21 21l-6 -6"></path>
                </svg>) : (<img src={CloseIcon} className="icon searchclear" onClick={() => ClearSearchFiled()} alt="close" />)}
            </div>
            <div className="card-header-right">
                <select className="form-control activestatusselet" value={orderType} onChange={(e) => SelectProductTypeFnct(e)}>
                    <option value="all">All</option>
                    <option value="paid">Paid</option>
                    <option value="unpaid">Unpaid</option>
                </select>
                <select className="form-control userLimitselect" value={listLimit} onChange={(e) => ProductLimit(e)}>
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="30">30</option>
                    <option value="40">40</option>
                    <option value="50">50</option>
                    <option value="60">60</option>
                    <option value="70">70</option>
                    <option value="80">80</option>
                    <option value="90">90</option>
                    <option value="100">100</option>
                </select>
            </div>
        </div>
        <div className="card-table-new">
            <div className="card-table table-responsive">
                <table className="table table-vcenter card-table order-managment-table">
                    <thead>
                        <tr>
                            <th>First Name</th>
                            <th>Last Name</th>
                            <th>Email</th>
                            <th>Phone</th>
                            <th>Country</th>
                            <th>Products</th>
                            <th>Created At</th>
                            <th>Total Amount</th>
                            <th>Payment Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {orderlist && orderlist?.length === 0 ? (<tr>
                            <td colSpan="10" style={{ textAlign: "center" }}>No Record Found</td>
                        </tr>) : orderlist?.map((orders, index) => {
                            return(<tr key={index}>
                                <td>{orders?.first_name}</td>
                                <td>{orders?.last_name}</td>
                                <td>{orders?.email}</td>
                                <td>{orders?.phone}</td>
                                <td>{orders?.country}</td>
                                <td>{ProductCartList(orders?.cart)}</td>
                                <td>{timeSince(new Date(orders?.created_at))}</td>
                                <td>${orders?.total_amount}</td>
                                <td>
                                {orders?.is_paid ? <span className="badge bg-green-lt">Paid</span> :
                                <span className="badge bg-red-lt">Failed</span>}
                                </td>
                            </tr>)
                        })}
                    </tbody>
                </table>
            </div>
            {orderlist?.length > 0 && (<PaginationPage 
                paginationLength={paginationLength}
                totalPages={paginationLength?.total_records}
                currentPage={currentPage}
                onChangePage={currentFunction}
                userLimit={listLimit}
            />)}
        </div>
    </div>

    {ListLoader && (<CommonLoaderNew />)}
</div>)
}

export default UserManagement;