/* eslint-disable */
import React, { useEffect, useState } from "react";
import EditIcon from '../../assets/img/edit.png';
import CommonPopup from "../Common/CommonPopup";
import PaginationPage from "../Common/PaginationPage";
import { getUserList, getIdWiseUserList, logoutUser } from "../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { GET_USERS_LIST_API_URL, LOGIN_URL } from "../Shared/Constant";
import moment from "moment";
import CloseIcon from '../../assets/img/closebutton.png';
import PreviewFiles from "../Common/PreviewFiles";
import CommonLoaderNew from "../Common/CommonLoaderNew";
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const timeSince = (dateParam)=> {
    if (!dateParam) {
        return null;
    }
    const date = typeof dateParam === 'object' ? dateParam : new Date(dateParam);
    const DAY_IN_MS = 86400000; // 24 * 60 * 60 * 1000
    const today = new Date();
    const yesterday = new Date(today - DAY_IN_MS);
    const seconds = Math.round((today - date) / 1000);
    const minutes = Math.round(seconds / 60);
    const isToday = today.toDateString() === date.toDateString();
    const isYesterday = yesterday.toDateString() === date.toDateString();
    const isThisYear = today.getFullYear() === date.getFullYear();
    
    if (seconds < 5) {
        return 'Today';
    } else if (seconds < 60) {
        return `Today`;
    } else if (seconds < 90) {
        return 'Today';
    } else if (minutes < 60) {
        return `Today`;
    } else if (isToday) {
        return 'Today'; // Today at 10:20
    } else if (isYesterday) {
        return 'Yesterday'; // Yesterday at 10:20
    } else if (isThisYear) {
        return moment.utc(dateParam).local().format('MMM D, YYYY'); // 10. January at 10:20
    }
    return moment.utc(dateParam).local().format('MMM D, YYYY'); // 10. January 2017. at 10:20
};

const UserManagement = () => {
    const { accessToken, userList, userObjects, userId, profiledata } = useSelector((state) => state.allReducers);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [ ModalCommon, setModalCommon ] = useState({
        open: false,
        title: "",
        submitbtn: "",
        cancelbtn: "",
        modaltype: ""
    });
    const [ userType, setUserType ] = useState("all");
    const [ userLimit, setUserLimit ] = useState(10);
    const [ userSearch, setUserSearch ] = useState("");
    const [ paginationLength, setPaginationLength ] = useState({});
    const [ currentPage, setCurrentPage ] = useState(1);
    const [ searchNone, setSearchNone ] = useState(false);
    const [ ListLoader, setListLoader ] = useState(false);

    // page change
    const currentFunction = (page) => {
        setCurrentPage(page);
    };

    // clear search box
    const ClearSearchFiled = () => {
        setUserSearch("");
        setSearchNone(true);
    };

    // select user type
    const SelectUserTypeFnct = (e) => {
        setUserType(e.target.value);
        setCurrentPage(1);
    };

    const selectLimitProduct = (value) => {
        setUserLimit(value);
        setCurrentPage(1);
    };

    useEffect(() => {
        GetUserListFnct();
    }, [ userType, userLimit, currentPage, searchNone ]);

    useEffect(() => {
        dispatch(getIdWiseUserList(userList));
    }, [ userList ]);

    // on Enter search
    const onEnterSearch = (e) => {
        const code = e.which || e.keyCode;
        if(code !== 17 && code !== 18) {
            if(code === 13) {
                GetUserListFnct(1);
            } else if(userSearch === "" || userSearch?.length <= 1) {
                GetUserListFnct();
            };
        };
    };

    // get user list
    const GetUserListFnct = (pages) => {
        setListLoader(true);
        if(pages !== undefined) {
            setCurrentPage(pages);
        };
        window.axios.get(`${GET_USERS_LIST_API_URL}/${userType}/${userLimit}/${pages === undefined ? currentPage : pages}/${userSearch === "" ? "none" : userSearch}`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + accessToken,
            }
        }).then(function (result) {
            setListLoader(false);
            dispatch(getUserList(result.data.data));
            setPaginationLength(result.data.pagination);
            setSearchNone(false);
        })  
        .catch(function (result) {
            setListLoader(false);
            if(result?.response?.status === 403) {
                dispatch(logoutUser());
                navigate(LOGIN_URL);
            } else if(result?.response?.status === 400) {
                if(result?.response?.data?.error === "Please login with admin user.") {
                    toast.error(result?.response?.data?.error);
                    dispatch(logoutUser());
                    setTimeout(() => navigate(LOGIN_URL), 100);
                } else {
                    toast.error(result?.response?.data?.error);
                };
            };
        });
    };

    // edit User function
    const EditUser = (user) => {
        setModalCommon({
            ...ModalCommon,
            open: !ModalCommon?.open,
            title: "Edit User",
            submitbtn: "Save",
            cancelbtn: "Cancel",
            modaltype: "form",
            selectedUser: user,
            type: "Edit User"
        });
    };

    // user allow to admin access
    const userAdminInfo = (user) => {
        setModalCommon({
            ...ModalCommon,
            open: !ModalCommon?.open,
            title: "Are you sure?",
            description: user?.is_admin === true ? 'Do you really want to remove admin to' : 'Do you really want to admin to',
            submitbtn: user?.is_admin === true ? "Remove" : "Yes",
            cancelbtn: "No",
            modaltype: "permision",
            selectedUser: user,
            type: "is admin user"
        });
    };

    // user active or deactivate
    const userActiveInfo = (user) => {
        setModalCommon({
            ...ModalCommon,
            open: !ModalCommon?.open,
            title: "Are you sure?",
            description: user?.is_active === true ? 'Do you really want to remove active user to' : 'Do you really want to active user to',
            submitbtn: user?.is_active === true ? "Remove" : "Yes",
            cancelbtn: "No",
            modaltype: "permision",
            selectedUser: user,
            type: "is active user"
        });
    };

    // user active or deactivate
    const userStaffInfo = (user) => {
        setModalCommon({
            ...ModalCommon,
            open: !ModalCommon?.open,
            title: "Are you sure?",
            description: user?.is_staff === true ? 'Do you really want to remove staff user to' : 'Do you really want to staff user to',
            submitbtn: user?.is_staff === true ? "Remove" : "Yes",
            cancelbtn: "No",
            modaltype: "permision",
            selectedUser: user,
            type: "is staff user"
        });
    };

    // user active or deactivate
    const CreateNewUser = () => {
        setModalCommon({
            ...ModalCommon,
            open: !ModalCommon?.open,
            title: "Add User",
            description: null,
            submitbtn: "Add",
            cancelbtn: "Cancel",
            modaltype: "form",
            selectedUser: {},
            type: "Add User"
        });
    };

    return(<div className="col-sm-12 col-lg-12">
    <div className="card-body">
        <div className="card-header-New">
            <div className="searchBoxwithbtn" style={{ width: "300px" }}>
                {userSearch !== "" && (<label id="focusLabel" className="focusLabel">Search after hitting the enter button.</label>)}
                <input type="text" className="form-control" value={userSearch} onKeyDown={(e) => onEnterSearch(e)} onChange={(e) => setUserSearch(e.target.value)} placeholder="Search User (First name, last name, email)..."/>
                {userSearch === "" ? (<svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-search" width={24} height={24} viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0"></path>
                    <path d="M21 21l-6 -6"></path>
                </svg>) : (<img src={CloseIcon} className="icon searchclear" onClick={() => ClearSearchFiled()} alt="close" />)}
            </div>
            <div className="card-header-right">
                <select className="form-control activestatusselet" value={userType} onChange={(e) => SelectUserTypeFnct(e)}>
                    <option value="all">All</option>
                    <option value="active">Active</option>
                    <option value="inactive">Inactive</option>
                </select>
                <select className="form-control userLimitselect" value={userLimit} onChange={(e) => selectLimitProduct(e.target.value)}>
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="30">30</option>
                    <option value="40">40</option>
                    <option value="50">50</option>
                    <option value="60">60</option>
                    <option value="70">70</option>
                    <option value="80">80</option>
                    <option value="90">90</option>
                    <option value="100">100</option>
                </select>
                {(profiledata?.is_admin || profiledata?.permission?.includes("add-user")) && (<button type="button" className="btn addusebtn" onClick={() => CreateNewUser()}>Add User</button>)}
            </div>
        </div>
        <div className="card-table-new">
            <div className="card-table table-responsive">
                <table className="table table-vcenter card-table">
                    <thead>
                        <tr>
                            <th>First Name</th>
                            <th>Last Name</th>
                            <th>Profile Picture</th>
                            <th>Email</th>
                            <th>Phone</th>
                            <th>Created At</th>
                            <th>Updated At</th>
                            <th>Role</th>
                            {/* {(profiledata?.is_admin || profiledata?.permission?.includes("update-user")) && (<th>Admin Role</th>)} */}
                            {(profiledata?.is_admin || profiledata?.permission?.includes("update-user")) && (<th>User Active</th>)}
                            {/* {(profiledata?.is_admin || profiledata?.permission?.includes("update-user")) && (<th>User Staff</th>)} */}
                            {(profiledata?.is_admin || profiledata?.permission?.includes("update-user")) && (<th>Action</th>)}
                        </tr>
                    </thead>
                    <tbody>
                        {userList && userList?.length === 0 ? (<tr>
                            <td colSpan="10" style={{ textAlign: "center" }}>No User Record Found</td>
                        </tr>) : userList?.map((user, index) => {
                            if(user?.id !== userId) {
                                return(<tr key={index}>
                                    <td>{user?.first_name === null ? "-" : user?.first_name}</td>
                                    <td>{user?.last_name === null ? "-" : user?.last_name}</td>
                                    <td className="userAvatar">
                                        <PreviewFiles type="image" url={user?.user_avatar_path} />
                                    </td>
                                    <td>{user?.email}</td>
                                    <td style={{ whiteSpace: "nowrap" }}>{user?.phone === null ? "-" : "+" + user?.phone}</td>
                                    <td>{timeSince(new Date(user.created_at))}</td>
                                    <td>{timeSince(new Date(user.updated_at))}</td>
                                    {/* {(profiledata?.is_admin || profiledata?.permission?.includes("update-user")) && (<td>
                                        <label className="form-check form-switch">
                                            <input className="form-check-input"  type="checkbox" onChange={e => {}} onClick={() => userAdminInfo(user)} checked={user?.is_admin === true ? true : false} />
                                            <span className="form-check-label" >
                                                <div className="post__content" dangerouslySetInnerHTML={{ __html: user?.is_admin === true ? "<span class='badge bg-green-lt'>Yes</span>" : "<span class='badge bg-red-lt'>No</span>"}}></div>
                                            </span>
                                        </label>
                                    </td>)} */}
                                    <td>
                                        <span className="form-check-label" >
                                            <div className="post__content" >
                                                {user?.is_staff === true && (<span className='badge bg-success-lt'>Staff</span>)}
                                                {user?.is_admin === true && (<span className='badge bg-success-lt'>Admin</span>)}
                                                {(user?.is_admin === false && user?.is_staff === false) && (<span className='badge bg-success-lt'>User</span>)}
                                            </div>
                                        </span>
                                    </td>
                                    {(profiledata?.is_admin || profiledata?.permission?.includes("update-user")) && (<td>
                                        <label className="form-check form-switch">
                                            <input className="form-check-input"  type="checkbox" onChange={e => {}} onClick={() => userActiveInfo(user)} checked={user?.is_active === true ? true : false} />
                                            <span className="form-check-label" >
                                                <div className="post__content" dangerouslySetInnerHTML={{ __html: user?.is_active === true ? "<span class='badge bg-success-lt'>Yes</span>" : "<span class='badge bg-red-lt'>No</span>"}}></div>
                                            </span>
                                        </label>
                                    </td>)}
                                    {/* {(profiledata?.is_admin || profiledata?.permission?.includes("update-user")) && (<td>
                                        <label className="form-check form-switch">
                                            <input className="form-check-input"  type="checkbox" onChange={e => {}} onClick={() => userStaffInfo(user)} checked={user?.is_staff === true ? true : false} />
                                            <span className="form-check-label" >
                                                <div className="post__content" dangerouslySetInnerHTML={{ __html: user?.is_staff === true ? "<span class='badge bg-success-lt'>Yes</span>" : "<span class='badge bg-red-lt'>No</span>"}}></div>
                                            </span>
                                        </label>
                                    </td>)} */}
                                    {(profiledata?.is_admin || profiledata?.permission?.includes("update-user")) && (<td>
                                        <button className="btn btntable" onClick={() => EditUser(user)}>
                                            <img src={EditIcon} alt="Edit" />
                                        </button>
                                    </td>)}
                                </tr>)
                            }
                        })}
                    </tbody>
                </table>
            </div>
            {userList?.length > 0 && (<PaginationPage 
                paginationLength={paginationLength}
                totalPages={paginationLength?.total_records}
                currentPage={currentPage}
                onChangePage={currentFunction}
                userLimit={userLimit}
            />)}
        </div>
    </div>

    {ListLoader && (<CommonLoaderNew />)}

    {/* common modal */}
    {ModalCommon?.open && (<CommonPopup ModalCommon={ModalCommon} setModalCommon={setModalCommon} userObjects={userObjects} />)}
</div>)
}

export default UserManagement;